import { environment } from '@env/environment';


export namespace Constants {
  export class SSO {
    public static LOGIN_URL = environment.ssoApiURL + '/auth';
    public static CLIENT_ID = environment.ssoClientID;
    public static LOGIN_REDIRECT_URL = window.location.origin + '/login';
    public static LOGIN_RESPONSE_TYPE = 'code';
    public static LOGIN_NONCE = 'FLPITITAN';
    public static LOGOUT_URL = environment.ssoApiURL + '/logout';
    public static ACCESS_TOKEN = 'appflp360.' + environment.accessToken;
    public static REFRESH_TOKEN = 'appflp360.' + environment.refreshToken;
    public static NONCE = environment.idNonce;
    public static MEMBER_ID = environment.memberId;
    public static ENCRYPTION_KEY = 'j1wootxJBEgAUJHyQ2XDbQ==';
    public static MEMBER_SESSIONID = 'EXT_MemberSessionGuid' + '_' + 'DEV1';
    public static FLP360_MEMBER_SESSION_STATE = 'FLP360MemberSessionState';
    public static FLP360_MEMBER_ID = 'MID_FLP360';
    public static IS_NEW_TOKEN = 'isNewToken';
    public static LOCAL_MEMBER_SESSION_ID = 'memberSessionId';
    public static MEMBER_SESSION_STATE = 'EXT_EPSS';
    public static USER_INFO = 'userInfo';
    public static EPSS = 'EPSS';
    public static CURRENT_ORGIN=window.location.origin;
    public static CURRENT_URL=window.location.href;
    public static getNonce(): string {
      let d = new Date().getTime();

      if (window.performance && typeof window.performance.now === 'function') {
        d += performance.now();
      }

      const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(
        c
      ) {
        const r = ((d + Math.random() * 16) % 16) | 0;
        d = Math.floor(d / 16);
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
      });

      return uuid;
    }

  }
}
