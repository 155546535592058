declare var apiConfig: any;
export const environment = {
  production: true,
  customerName: apiConfig.customerName,
  siteTrackerVersionNo: apiConfig.siteTrackerVersionNo,
  apiUrl: apiConfig.apiGatewayURL || 'https://apigw-titandev1.nextsphere.com/api',
  reportApi: apiConfig.reportApi || 'reporttdm',
  ssoApiURL: apiConfig.ssoApiURL || 'https://sso-titandev1.nextsphere.com/auth/realms/FLP360/protocol/openid-connect',
  ssoClientID: apiConfig.ssoClientID || 'FLP360-WebApp',
  accountSiteURL: apiConfig.accountSiteURL,
  uiTemplateBaseUrl: document.getElementsByTagName('base')[0].href + '/resources/static/src/assets',
  baseHref: document.getElementsByTagName('base')[0].href,
  accessToken: apiConfig.accessToken || 'EXT_AT_DEV1',
  refreshToken: apiConfig.refreshToken || 'EXT_RT_DEV1',
  paymentGatewayToken: apiConfig.paymentGatewayToken || 'GvtQwfLQxfWwQQZBHX0eVFWbYJEbzmjewiHXZV7rEVc=',
  paymentSecText: apiConfig.paymentSecurityKey ,
  idNonce: apiConfig.idNonce || 'EXT_IDNC_DEV1',
  memberId: apiConfig.memberId || 'EXT_MID_DEV1',
  cdnURL: apiConfig.cdnURL,
  cmsTemplateStylePath: apiConfig.cmsTemplateStylePath,
  cmsTemplateScriptPath: apiConfig.cmsTemplateScriptPath,
  cmsTemplateStyleIntegrity: apiConfig.cmsTemplateStyleIntegrity,
  cmsTemplateScriptIntegrity: apiConfig.cmsTemplateScriptIntegrity,
  s3BucketURL: apiConfig.s3BucketURL,
  cookiePath: apiConfig.cookiePath || '/',
  cookieDomain: apiConfig.cookieDomain || '.nextsphere.com',
 //  ssoClientSecret: '29e43d3c-e35b-485d-9d93-1762014fff35',
  ssoClientAssertion: apiConfig.ssoClientAssertion,
  baseUrl: apiConfig.baseURL || 'https://flp360-dev.nextsphere.com',
  socialsalesMenuBaseUrl: apiConfig.socialsalesMenuBaseUrl || 'https://dev.socialsales.io/integration/sso',
  socialsalesProviderID: apiConfig.socialsalesProviderID || 'FLPI',
  socialsalesHideMenu: apiConfig.socialsalesHideMenu || '7',
  socialsalesBaseUrl: apiConfig.socialsalesBaseUrl || 'https://dev.socialsales.io/api/',
  socialsalesClientSecretKey: apiConfig.socialsalesClientSecretKey ,
  envName: apiConfig.envName || 'DEV1',
  chargebeeSiteName: apiConfig.chargebeeSiteName || 'flp360-test',
  chargebeePublishableKey: apiConfig.chargebeePublishableKey || 'test_Oc5Tu0LRGXn4A4ajfm7U1CTFxCeI9Lb2',
  corporateURL: apiConfig.corporateURL,
  enrollSiteURL: apiConfig.enrollSiteURL,
  isChargebeeProduction: apiConfig.isChargebeeProduction || false,
  slugName: apiConfig.slugName || 'faq',
  chargebeeDefaultEmailValue: apiConfig.defaultEmailValue || '@SharkLasers.com',
  flp360Url: apiConfig.flp360Url,
  customerCareEmailId: apiConfig.customerCareEmailId,
  flp360ReportsVideoUrl: apiConfig.enrollmentReportsVideoUrl,
  flp360ToolsVideoUrl: apiConfig.enrollmentToolsVideoUrl,
  flp360ReportsPlayStoreLink: apiConfig.reportsPlayStoreLink,
  flp360ReportsAppStoreLink: apiConfig.reportsAppStoreLink,
  flp360ToolsPlayStoreLink: apiConfig.toolsPlayStoreLink,
  flp360ToolsAppStoreLink: apiConfig.toolsAppStoreLink,
  // awsBucket: apiConfig.awsBucketName,
  // awsSecretKey: apiConfig.awsSecretKey,
  // awsAccessKey: apiConfig.awsAccessKey,
  // awsRegion: apiConfig.awsRegion,
  enrollmentTitle: apiConfig.enrollmentTitle,
  enrollmentDescription: apiConfig.enrollmentDescription,
  toolsTitle: apiConfig.toolsTitle,
  toolsDescription: apiConfig.toolsDescription,
  reportsTitle: apiConfig.reportsTitle,
  reportsDescription: apiConfig.reportsDescription,
  reportUrl: apiConfig.reportUrl,
  toolsUrl: apiConfig.toolsUrl,
  advancedReportsSlugName: apiConfig.advancedReportsSlugName,
  enableTrainingPopup: apiConfig.enableTrainingPopup,
  landingPageVideoUrl: apiConfig.landingPageVideoUrl || "https://player.vimeo.com/video/388312253?app_id=122963",
  enableEnrollment: apiConfig.enableEnrollment,
  chargebeeNotSupportedLanguages: apiConfig.chargebeeNotSupportedLanguages,
  disableLiveChat: apiConfig.disableLiveChat,
  preferredCustomerInfoPage: apiConfig.preferredCustomerInfoPage || 'https://foreverliving.com/{{country}}/{{language}}/welcome',
  s3BaseUrl: apiConfig.s3BaseUrl || 'https://titandevenv.s3.us-west-2.amazonaws.com',
  pwaUrl: apiConfig.pwaUrl,

  countryMergeConfiguration: apiConfig.countryMergeConfiguration || '[{"mergeTo": "FRA","mergingCountries":[{"operatingCountryCode": "PRT", "text": "Data until December 2021", "notAvailableTest":"Not available. Please select France"},{"operatingCountryCode": "ESP", "text": "Data until December 2021", "notAvailableTest":"Not available. Please select France"}]}]',
  enableDownlineExcelQueryGeneration: apiConfig.enableDownlineExcelQueryGeneration || false,
  enableFileUploadToPrivate: apiConfig.enableFileUploadToPrivate || false,
  isDemoAccount: apiConfig.isDemoAccount || false,
  actualDomainPrefix: apiConfig.actualDomainPrefix || '',
  demoDomainPrefix: apiConfig.demoDomainPrefix || '',
  demoCountry: apiConfig.demoCountry || 'LCA',
  enableDownlineManagers: apiConfig.enableDownlineManagers || false,
  isEnableOfflineTokenFlow : apiConfig.enableOfflineTitanFlow || false,
  enableNewGlobalRallyReport : apiConfig.enableNewGlobalRallyReport || false,
  enableDownlineReport: apiConfig.enableDownlineReport || false,
  enableCSPV2TicketCreation: apiConfig.enableCSPV2TicketCreation || false,
  cspv2LiveCountries: apiConfig.cspv2LiveCountries || 'USA,CAN',
  ecommerceUrl: apiConfig.ecommerceUrl,
  enableBonusRecap: apiConfig.enableBonusRecap || false,
  enableAlertsAndActions : apiConfig.enableAlertsAndActions || false,
  enableVATRecapChanges: apiConfig.enableVATRecapChanges || false,
  enablePhase2RewireReports:apiConfig.enablePhase2RewireReports,
  enableNewBonusDetailRecap:apiConfig.enableNewBonusDetailRecap || false,
  enableEnrollmentService: apiConfig.enableEnrollmentService || false,
  tenCCSupervisorEnabledCountries: apiConfig.tenCCSupervisorEnabledCountries || 'GBR,IRL,DEU,CHE',
  enableNewNextLevelGoalChanges: apiConfig.enableNewNextLevelGoalChanges || false,
  enableMfaMethod: apiConfig.enableMfaMethod || false,
  externalUserLogoutUrl: apiConfig.externalUserLogoutUrl || 'https://sso-dev.flptitan.com/auth/realms/master/custom/realms/'

};
