import { AfterViewInit, ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { CacheKeyConstants } from '@core/constants/cachekey.constant';
import { CultureCodeConstants } from '@core/constants/culture-code.constants';
import { ImageConstants } from '@core/constants/images.constant';
import { RoutingConstants } from '@core/constants/routing.constants';
import { Constants } from '@core/constants/sso.constants';
import { Member } from '@core/models/member';
import { CacheService } from '@core/services/cache.service';
import { MessageService } from '@core/services/message-service.service';
import { SsoService } from '@core/services/sso.service';
import { environment } from '@env/environment';
import { CulturecodeModel } from '@modules/account/models/culturecode';
import { FLP360Status } from '@modules/enrollment/enums/flp360-status.enum';
import { LocalStorageService } from 'angular-2-local-storage';
import { Subscription } from 'rxjs';
import { GuidedTourConstants } from '../constants/guided-tour.constants';

@Component({
  selector: 'app-navbar',
  templateUrl: '../templates/template1/views/navbar.component.html',
  styleUrls: ['../templates/template1/themes/navbar.component.less'],
  providers: []
})
export class NavbarComponent implements OnInit, OnDestroy, AfterViewInit {
  isRTL: boolean;
  isNav: boolean;
  flp360FboId: number;
  showNavigation: boolean;
  userName: string;
  order: string;
  earnings: string;
  incentives: string;
  focusGroup: string;
  downline: string;
  downlineManagers: string;
  retailCustomer: string;
  recruitments: string;
  retention: string;
  productivity: string;
  development: string;
  volume: string;
  recruitmentUrl: string;
  productivityUrl: string;
  earningsUrl: string;
  customerUrl: string;
  developmentUrl: string;
  downlineUrl: string;
  userDetails: Member;
  dashboardUrl: string;
  activityFeedUrl: string;
  contactsUrl: string;
  mediaLibraryUrl: string;
  linkBuilderUrl: string;
  landingPagesUrl: string;
  callToActionUrl: string;
  socialQueueUrl: string;
  contentStreamUrl: string;
  openDashboardItems = false;
  openToolsItems = false;
  openTrainingToolsItems=false;
  toggleStatus: any;
  isSubMenuOpened = true;
  subscription: Subscription;
  nonTitanUserTitleSubscription: Subscription;
  toggleStatusSubscription: Subscription;
  homeCountryCodeSubscription: Subscription;
  navbarSubmenuStatusSubscription: Subscription;
  sideNavOpenSubscription: Subscription;
  routedPathSubscription: Subscription;
  closeReportsMenuStatusSubscription: Subscription;
  closeToolsMenuStatusSubscription: Subscription;
  socialSalesRoutingPageSubscription: Subscription;
  showReportsSubMenuSubscription: Subscription;
  iscloseAllmenuSubscription: Subscription;
  isSuspendedUserSubscription: Subscription;
  showAccountsSubMenuSubscription: Subscription;
  menuActive: string;
  eventSubscription: any;
  openMyAccountSettings = false;
  openHelpMenu = false;
  subscriptionAndBilling: string;
  personalInformation: string;
  locationsAndLanguages: string;
  pdfGuides: string;
  //supportRequest: string;
  contactUs: string;
  videoTutorials: string;
  resources: string;
  faq: string;
  userDetail: any;
  contentChannelUrl: string;
  accountWebsiteUrl: string;
  appsPage: string;
  homeCountryName: string;
  homeCountryCode: string;
  introspectResponse = false;
  introspectCall = false;
  isImpersonation: string;
  accessibilityStatement: string;
  trackingSettingsUrl: string;
  advancedReports: string;
  reporterizerWorkflow: string;
  additionalReport: string;
  selectedLanguage: string;
  isFlp360AccountEligible = false;
  userMemberTitle: string;
  showTourPopup: boolean;
  selectedTour: string;
  dashboardImage = ImageConstants.DASHBOARD_IMAGE;
  reportsImage = ImageConstants.REPORTS_IMAGE;
  toolsImage = ImageConstants.TOOLS_IMAGE;
  trainingToolsImage=ImageConstants.TRAINING_TOOLS;
  appsImage = ImageConstants.APPS_IMAGE;
  accountImage = ImageConstants.ACCOUNT_IMAGE;
  helpImage = ImageConstants.HELP_IMAGE;
  logoutImage = ImageConstants.LOGOUT_IMAGE;
  openContentItems = false;
  earningsCCSummary = RoutingConstants.EARNINGS_CC_SUMMARY;
  earnings25ccManager = RoutingConstants.EARNINGS_25CC_MANAGERS;
  isBackgroundImageShown: boolean = true;
  fourCoreSummary = RoutingConstants.FOUR_CORE_SUMMARY;
  fourCoreRrecruitment = RoutingConstants.RECURITMENTS;
  fourCoreRetention = RoutingConstants.RETENTION_NAV;
  fourCoreProductivity = RoutingConstants.PRODUCTIVITY_NAV;
  fourCoreLevelMoveUp = RoutingConstants.FOUR_CORE_DEVELOPMENT;
  openFourCoreContentList: boolean = false;
  businesstrainingUrl: string;
  producttrainingUrl: string;
  availableLanguages: CulturecodeModel[] = [];
  enableMfaMethod: boolean = environment && environment.enableMfaMethod;

  constructor(
    private localStorageService: LocalStorageService,
    private router: Router,
    private ssoService: SsoService,
    private messageService: MessageService,
    private cacheService: CacheService,
    private cdRef: ChangeDetectorRef
  ) {
    this.messageService.getIsBackgroundImageShown().subscribe(message => {
      this.isBackgroundImageShown = message;
    })
    this.nonTitanUserTitleSubscription = this.messageService
      .getNonTitanUserTitle()
      .subscribe(title => {
        if (title) {
          this.userMemberTitle = title.text;
        }
      });
    this.toggleStatusSubscription = this.messageService.getToggleStatus().subscribe(message => {
      this.toggleStatus = message.text;
    });
    this.routedPathSubscription = this.messageService.getRoutedPath().subscribe(message => {
      this.closeAllMenus();
      this.getRoutedPath();
    });
    this.navbarSubmenuStatusSubscription = this.messageService.getNavbarSubmenuStatus().subscribe(message => {
      this.closeAllMenus();
    });
    this.sideNavOpenSubscription = this.messageService.getSideNavOpen().subscribe(response => {
      const element: any = document.getElementById('sidebartoggler');
      if (element.checked) {
        document.getElementById('sideNavToggleIcon').click();
      }
    });
    this.eventSubscription = this.router.events.subscribe((event: any) => {
      let otherReport = false;
      this.isBackgroundImageShown = true;
      let pathSplit = window.location.pathname.split('/');
      if (RoutingConstants.NO_BACKGROUND_REPORTS.indexOf(pathSplit[pathSplit.length -1 ]) > -1) {
        this.isBackgroundImageShown = false;
      }
      if (event instanceof NavigationEnd) {
        otherReport = this.getRoutedPath();
      }
      // Advanced Reports Hide sideNav
      if (otherReport) {
        let isAdvancedReport = false;
        RoutingConstants.ALL_ADVANCED_REPORTS.forEach((element) => {
          if (window.location.pathname.indexOf(element) > -1) {
            isAdvancedReport = true;
          }
        })
        if (isAdvancedReport) {
          this.isNav = false;
        } else if (window.location.pathname.indexOf(RoutingConstants.ACCESSIBILITY_URL) > -1) {
          this.menuActive = ' ';
          this.closeAllMenus();
        }
      } else {
        this.isNav = true;
      }
    });

    this.closeReportsMenuStatusSubscription = this.messageService.getCloseReportsMenuStatus().subscribe(message => {
      this.openDashboard();
    });
    this.closeToolsMenuStatusSubscription = this.messageService.getCloseToolsMenuStatus().subscribe(message => {
      this.openTools();
    });
    this.socialSalesRoutingPageSubscription = this.messageService.getSocialSalesRoutingPage().subscribe(routedUrl => {
      const socialsalesRoutedUrl = routedUrl;
      if (socialsalesRoutedUrl.text.indexOf(RoutingConstants.SOCIAL_QUEUES) > -1) {
        this.openToolsItems = true;
        this.menuActive = RoutingConstants.SOCIAL_QUEUES;
      } else if (socialsalesRoutedUrl.text.indexOf(RoutingConstants.CALL_TO_ACTION) > -1) {
        this.openToolsItems = true;
        this.menuActive = RoutingConstants.CALL_TO_ACTION;
      } else if (socialsalesRoutedUrl.text.indexOf(RoutingConstants.SOCIAL_PAGES) > -1) {
        this.openToolsItems = true;
        this.menuActive = RoutingConstants.SOCIAL_PAGES;
      } else if (socialsalesRoutedUrl.text.indexOf(RoutingConstants.LINK_BUILDER) > -1) {
        this.openToolsItems = true;
        this.menuActive = RoutingConstants.LINK_BUILDER;
      } else if (socialsalesRoutedUrl.text.indexOf(RoutingConstants.MEDIA_LIBRARYS) > -1) {
        this.openToolsItems = true;
        this.menuActive = RoutingConstants.MEDIA_LIBRARYS;
      } else if (socialsalesRoutedUrl.text.indexOf(RoutingConstants.ACTIVITY_FEED) > -1) {
        this.openToolsItems = true;
        this.menuActive = RoutingConstants.ACTIVITY_FEED;
      } else if (socialsalesRoutedUrl.text.indexOf(RoutingConstants.CONTACTS) > -1) {
        this.openToolsItems = true;
        this.menuActive = RoutingConstants.CONTACTS;
      } else if (socialsalesRoutedUrl.text.indexOf(RoutingConstants.CONTENT_STREAM_URL)) {
        this.openToolsItems = true;
        this.menuActive = RoutingConstants.CONTENT_STREAM_URL;
      }
    });
    this.showReportsSubMenuSubscription = this.messageService.getIsshowReportsSubMenu().subscribe(response => {
      if (response) {
        setTimeout(() => {
          this.openReportsTourPopup('reportsTour')
        } , 500);

      }
    });
    this.showAccountsSubMenuSubscription = this.messageService.getIsshowAccountSubMenu().subscribe(response => {
      if (response) {
        this.openHelp();
      }
    });
    this.iscloseAllmenuSubscription = this.messageService.getIscloseAllmenu().subscribe(response => {
      if (response) {
        this.closeAllMenus();
      }
    })
    this.isSuspendedUserSubscription = this.messageService.getIsMemberSuspended().subscribe(message => {
      if (message) {
        this.showNavigation = false;
       this.getReportsUrlPath();
      }else{
        this.showNavigation = true;
        this.getReportsUrlPath();
      }
    });
  }
  getRoutedPath(): boolean {
    let otherReport = false;
    const fboId = sessionStorage.getItem('fboID') ? CacheKeyConstants.getDecodedDataForNumber(sessionStorage.getItem('fboID')) : sessionStorage.getItem('fboID');
    if (window.location.pathname.split('/').indexOf(RoutingConstants.DASHBOARD) > -1) {
      this.menuActive = RoutingConstants.DASHBOARD_URL;
    } else if (window.location.pathname.split('/').indexOf(RoutingConstants.VOLUME_NAV) > -1) {
      this.menuActive = RoutingConstants.VOLUME_NAV;
      this.openDashboardItems = true;
    } else if (window.location.pathname.split('/').indexOf(RoutingConstants.ORDERS_NAV) > -1) {
      this.openDashboardItems = true;
      this.menuActive = RoutingConstants.ORDERS_NAV;
    } else if (window.location.pathname.split('/').indexOf(RoutingConstants.EARNINGS_NAV) > -1) {
      this.openDashboardItems = true;
      this.menuActive = RoutingConstants.EARNINGS_NAV;
    } else if (window.location.pathname.split('/').indexOf(RoutingConstants.INCENTIVES_NAV) > -1) {
      this.openDashboardItems = true;
      this.menuActive = RoutingConstants.INCENTIVES_NAV;
    }else if (window.location.pathname.split('/').indexOf(RoutingConstants.FOCUS_GROUP_NAV) > -1) {
      this.openDashboardItems = true;
      this.menuActive = RoutingConstants.FOCUS_GROUP;
    } else if (window.location.pathname.split('/').indexOf(RoutingConstants.DOWNLINE_NAV) > -1) {
      this.openDashboardItems = true;
      this.menuActive = RoutingConstants.DOWNLINE_NAV;
    } else if (window.location.pathname.split('/').indexOf(RoutingConstants.RETAIL_CUSTOMERS_NAV) > -1) {
      this.openDashboardItems = true;
      this.menuActive = RoutingConstants.RETAIL_CUSTOMERS_NAV;
    } else if (window.location.pathname.split('/').indexOf(RoutingConstants.RECURITMENT_NAV) > -1) {
      this.openDashboardItems = true;
      this.openFourCoreContentList = true;
      this.menuActive = RoutingConstants.RECURITMENT_NAV;
    } else if (window.location.pathname.split('/').indexOf(RoutingConstants.RETENTION_NAV) > -1) {
      this.openDashboardItems = true;
      this.openFourCoreContentList = true;
      this.menuActive = RoutingConstants.RETENTION_NAV;
    } else if (window.location.pathname.split('/').indexOf(RoutingConstants.PRODUCTIVITY_NAV) > -1) {
      this.openDashboardItems = true;
      this.openFourCoreContentList = true;
      this.menuActive = RoutingConstants.PRODUCTIVITY_NAV;
    } else if (window.location.pathname.split('/').indexOf(RoutingConstants.DEVELOPMENT_NAV) > -1) {
      this.openDashboardItems = true;
      this.menuActive = RoutingConstants.DEVELOPMENT_NAV;
    } else if (window.location.pathname.split('/').indexOf(RoutingConstants.ADVANCED_REPORTS) > -1) {
      this.openDashboardItems = true;
      this.menuActive = RoutingConstants.ADVANCED_REPORTS;
    } else if (window.location.pathname.split('/').indexOf(RoutingConstants.FOUR_CORE_SUMMARY) > -1) {
      this.openDashboardItems = true;
      this.openFourCoreContentList = true;
      this.menuActive = RoutingConstants.FOUR_CORE_SUMMARY;
    }
    else if (window.location.pathname.split('/').indexOf(RoutingConstants.FOUR_CORE_DEVELOPMENT) > -1) {
      this.openDashboardItems = true;
      this.menuActive = RoutingConstants.FOUR_CORE_DEVELOPMENT;
    }
     else if (window.location.pathname.split('/').indexOf(RoutingConstants.SOCIAL_QUEUES) > -1) {
      if (fboId && this.localStorageService.get(Constants.SSO.MEMBER_ID)) {
        if (fboId !== this.localStorageService.get(Constants.SSO.MEMBER_ID)) {
          window.location.reload();
        }
      }
      this.openToolsItems = true;
      this.menuActive = RoutingConstants.SOCIAL_QUEUES;
    } else if (window.location.pathname.split('/').indexOf(RoutingConstants.CALL_TO_ACTION) > -1) {
      if (fboId && this.localStorageService.get(Constants.SSO.MEMBER_ID)) {
        if (fboId !== this.localStorageService.get(Constants.SSO.MEMBER_ID)) {
          window.location.reload();
        }
      }
      this.openToolsItems = true;
      this.menuActive = RoutingConstants.CALL_TO_ACTION;
    } else if (window.location.pathname.split('/').indexOf(RoutingConstants.SOCIAL_PAGES) > -1) {
      if (fboId && this.localStorageService.get(Constants.SSO.MEMBER_ID)) {
        if (fboId !== this.localStorageService.get(Constants.SSO.MEMBER_ID)) {
          window.location.reload();
        }
      }
      this.openToolsItems = true;
      this.openContentItems = true;
      this.menuActive = RoutingConstants.SOCIAL_PAGES;
    } else if (window.location.pathname.split('/').indexOf(RoutingConstants.LINK_BUILDER) > -1) {
      if (fboId && this.localStorageService.get(Constants.SSO.MEMBER_ID)) {
        if (fboId !== this.localStorageService.get(Constants.SSO.MEMBER_ID)) {
          window.location.reload();
        }
      }
      this.openToolsItems = true;
      this.menuActive = RoutingConstants.LINK_BUILDER;
    } else if (window.location.pathname.split('/').indexOf(RoutingConstants.MEDIA_LIBRARYS) > -1) {
      if (fboId && this.localStorageService.get(Constants.SSO.MEMBER_ID)) {
        if (fboId !== this.localStorageService.get(Constants.SSO.MEMBER_ID)) {
          window.location.reload();
        }
      }
      this.openToolsItems = true;
      this.openContentItems = true;
      this.menuActive = RoutingConstants.MEDIA_LIBRARYS;
    } else if (window.location.pathname.split('/').indexOf(RoutingConstants.ACTIVITY_FEED) > -1) {
      if (fboId && this.localStorageService.get(Constants.SSO.MEMBER_ID)) {
        if (fboId !== this.localStorageService.get(Constants.SSO.MEMBER_ID)) {
          window.location.reload();
        }
      }
      this.openToolsItems = true;
      this.menuActive = RoutingConstants.ACTIVITY_FEED;
    } else if (window.location.pathname.split('/').indexOf(RoutingConstants.CONTACTS) > -1) {
      if (fboId && this.localStorageService.get(Constants.SSO.MEMBER_ID)) {
        if (fboId !== this.localStorageService.get(Constants.SSO.MEMBER_ID)) {
          window.location.reload();
        }
      }
      this.openToolsItems = true;
      this.menuActive = RoutingConstants.CONTACTS;
    } else if (window.location.pathname.split('/').indexOf(RoutingConstants.CONTENT_STREAM_URL) > -1) {
      if (fboId && this.localStorageService.get(Constants.SSO.MEMBER_ID)) {
        if (fboId !== this.localStorageService.get(Constants.SSO.MEMBER_ID)) {
          window.location.reload();
        }
      }
      this.openToolsItems = true;
      this.openContentItems = true;
      this.menuActive = RoutingConstants.CONTENT_STREAM_URL;
    } else if (window.location.pathname.split('/').indexOf(RoutingConstants.SUBSCRIPTION_BILLING_NAV) > -1) {
      this.openMyAccountSettings = true;
      this.menuActive = RoutingConstants.SUBSCRIPTION_BILLING_NAV;
    } else if (window.location.pathname.split('/').indexOf(RoutingConstants.PERSONAL_INFO_NAV) > -1) {
      this.openMyAccountSettings = true;
      this.menuActive = RoutingConstants.PERSONAL_INFO_NAV;
    } else if (window.location.pathname.split('/').indexOf(RoutingConstants.LOCATION_AND_LANGUAGES_NAV) > -1) {
      this.openMyAccountSettings = true;
      this.menuActive = RoutingConstants.LOCATION_AND_LANGUAGES_NAV;
    } else if (window.location.pathname.split('/').indexOf(RoutingConstants.CONTENT_CHANNEL_URL) > -1) {
      if (fboId && this.localStorageService.get(Constants.SSO.MEMBER_ID)) {
        if (fboId !== this.localStorageService.get(Constants.SSO.MEMBER_ID)) {
          window.location.reload();
        }
      }
      this.openMyAccountSettings = true;
      this.menuActive = RoutingConstants.CONTENT_CHANNEL_URL;
    } else if (window.location.pathname.split('/').indexOf(RoutingConstants.ONLINE_TRACKING_URL) > -1) {
      if (fboId && this.localStorageService.get(Constants.SSO.MEMBER_ID)) {
        if (fboId !== this.localStorageService.get(Constants.SSO.MEMBER_ID)) {
          window.location.reload();
        }
      }
      this.openMyAccountSettings = true;
      this.menuActive = RoutingConstants.ONLINE_TRACKING_URL;
    } else if (window.location.pathname.split('/').indexOf(RoutingConstants.SOCIALSALES_ACCOUNT_WEBSITE_URL) > -1) {
      if (fboId && this.localStorageService.get(Constants.SSO.MEMBER_ID)) {
        if (fboId !== this.localStorageService.get(Constants.SSO.MEMBER_ID)) {
          window.location.reload();
        }
      }
      this.openToolsItems = true;
      this.openContentItems = true;
      this.menuActive = RoutingConstants.SOCIALSALES_ACCOUNT_WEBSITE_URL;
    } else if (window.location.pathname.split('/').indexOf(RoutingConstants.CONTACT_US_NAV) > -1) {
      this.openHelpMenu = true;
      this.menuActive = RoutingConstants.CONTACT_US;
    } else if (window.location.pathname.split('/').indexOf(RoutingConstants.PDF_GUIDES_NAV) > -1) {
      if (fboId && this.localStorageService.get(Constants.SSO.MEMBER_ID)) {
        if (fboId !== this.localStorageService.get(Constants.SSO.MEMBER_ID)) {
          window.location.reload();
        }
      }
      this.openHelpMenu = true;
      this.menuActive = RoutingConstants.PDF_GUIDES_NAV;
    } else if (window.location.pathname.split('/').indexOf(RoutingConstants.VIDEO_TUTORIALS_NAV) > -1) {
      this.openHelpMenu = true;
      this.menuActive = RoutingConstants.VIDEO_TUTORIALS_NAV;
    } else if (window.location.pathname.split('/').indexOf(RoutingConstants.FAQ_NAV) > -1) {
      this.openHelpMenu = true;
      this.menuActive = RoutingConstants.FAQ_NAV;
    } else if (window.location.pathname.indexOf(RoutingConstants.APPS_PAGE_URL) > -1) {
      if (fboId && this.localStorageService.get(Constants.SSO.MEMBER_ID)) {
        if (fboId !== this.localStorageService.get(Constants.SSO.MEMBER_ID)) {
          window.location.reload();
        }
      }
      this.menuActive = RoutingConstants.APPS_PAGE_URL;
    }
    else if (window.location.pathname.split('/').indexOf(RoutingConstants.SECURITY_PREFERENCES) > -1) {
      if (fboId && this.localStorageService.get(Constants.SSO.MEMBER_ID)) {
        if (fboId !== this.localStorageService.get(Constants.SSO.MEMBER_ID)) {
          window.location.reload();
        }
      }
      this.openMyAccountSettings = true;
      this.menuActive = RoutingConstants.SECURITY_PREFERENCES;
    } else {
      otherReport = true;
    }
    return otherReport;
  }
  ngOnInit(): void {
    const screenSize = screen.width;
    if (screenSize <= 1024) {
      this.toggleStatus = false;
      this.messageService.sendToggleStatus(this.toggleStatus);
    }
    this.subscription = this.messageService
      .getIntrospectStatus()
      .subscribe(introspectStatus => {
        if (introspectStatus) {
          this.introspectResponse = true;
          if (this.localStorageService.get(Constants.SSO.MEMBER_ID)) {
            this.userName = this.localStorageService.get(Constants.SSO.MEMBER_ID);
            this.userDetails = JSON.parse(
              this.localStorageService.get(CacheKeyConstants.userInfo)
            );
            this.userDetails.distributorId = CacheKeyConstants.getDecodedDataForNumber(this.userDetails.distributorId);
            this.userMemberTitle = this.userDetails.memberTitleId ? this.userDetails.memberTitle : this.userMemberTitle;
            this.getPageDirection();
          }
          this.localStorageService.remove(CacheKeyConstants.IMPERSONATION);
          if (!this.introspectCall) {
            this.getFlp360StatusId();
          }
        }
      });
    this.isImpersonation = this.localStorageService.get(CacheKeyConstants.IMPERSONATION);
    if (
      this.localStorageService.get(CacheKeyConstants.userInfo) &&
      !this.introspectResponse && !this.isImpersonation
    ) {
      this.introspectCall = true;
      if (this.localStorageService.get(Constants.SSO.MEMBER_ID)) {
        this.userName = this.localStorageService.get(Constants.SSO.MEMBER_ID);
        this.userDetails = JSON.parse(
          this.localStorageService.get(CacheKeyConstants.userInfo)
        );
        this.userDetails.distributorId = CacheKeyConstants.getDecodedDataForNumber(this.userDetails.distributorId);
        this.getPageDirection();
      }
      this.getFlp360StatusId();
    }
  }
  ngAfterViewInit() {
    this.cdRef.detectChanges();
  }

  @HostListener('window:resize', ['$event'])
  onScreenResize($event: Event): void {
    const width = (window.innerWidth > 0) ? window.innerWidth : document.documentElement.clientWidth;
    this.cdRef.detectChanges();
  }

  /**
   * @description get FLP360 status Id
   * @date 2019-12-17
   * @memberof NavbarComponent
   */
  getFlp360StatusId(): void {
    const flp360StatusId = this.cacheService.get(CacheKeyConstants.FLP360FBOID);
    this.isFlp360AccountEligible = this.localStorageService.get('isFlp360Eligible') != null ? this.localStorageService.get('isFlp360Eligible') === true ? true : false : true;
    if (this.isFlp360AccountEligible) {
      if ((flp360StatusId === FLP360Status.Restricted) || (flp360StatusId === FLP360Status.Suspended)) {
        this.showNavigation = false;
      } else {
        this.showNavigation = true;
        this.loadDefaultSettings();
      }
    } else {
      this.showNavigation = false;
    }
  }

  /**
   * @description Load default settings
   * @date 2019-12-17
   * @memberof NavbarComponent
   */
  loadDefaultSettings(): void {
    this.getReportsUrlPath();
    if (this.localStorageService.get(CacheKeyConstants.userInfo) != null) {
      this.userDetails = JSON.parse(this.localStorageService.get(CacheKeyConstants.userInfo));
      if (this.userDetails === undefined || this.userDetails === null) {
        const redirectedUrl = this.ssoService.getSSOLogoutUrl(
          window.location.origin
        );
        this.ssoService.ssoRedirect(redirectedUrl);
      }
      this.userDetails.distributorId = CacheKeyConstants.getDecodedDataForNumber(this.userDetails.distributorId);
      this.homeCountryCode = this.userDetails.homeCountryCode;
    } else {
      this.homeCountryCodeSubscription = this.messageService.getHomeCountryCode().subscribe(data => {
        if (data) {
          this.homeCountryCode = data;
        }
      });
    }
  }

  getPageDirection() {
    this.selectedLanguage = this.localStorageService.get(CacheKeyConstants.selectedLanguage) ?
      this.localStorageService.get(CacheKeyConstants.selectedLanguage) : 'en-US';
      this.availableLanguages=CultureCodeConstants.UPDATED_LANGUAGE_LIST;
      this.availableLanguages.forEach((c, i) => {
      if (this.selectedLanguage.indexOf(c.languageCode) > -1) {
        this.isRTL = c.languageDirection === 'rtl' ? true : false;
      }
    });
    this.messageService.sendLanguageOrientation(this.isRTL);
  }

  /**
   * @description get URL paths
   * @date 2019-12-17
   * @memberof NavbarComponent
   */
  getReportsUrlPath(): void {
    this.order = RoutingConstants.ORDER;
    this.volume = RoutingConstants.VOLUME;
    this.earnings = RoutingConstants.EARNINGS;
    this.incentives = RoutingConstants.INCENTIVES;
    this.focusGroup = RoutingConstants.FOCUS_GROUP;
    this.downline = RoutingConstants.DOWNLINE;
    this.downlineManagers = RoutingConstants.DOWNLINE_MANAGERS;
    this.retailCustomer = RoutingConstants.RETAIL_CUSTOMERS;
    this.recruitments = RoutingConstants.RECURITMENTS;
    this.retention = RoutingConstants.RETENTION;
    this.productivity = RoutingConstants.PRODUCTIVITY;
    this.development = RoutingConstants.DEVELOPMENT;
    this.advancedReports = RoutingConstants.ADVANCED_REPORTS;
    this.reporterizerWorkflow = RoutingConstants.REPORTERIZER_WORKFLOW;
    this.additionalReport = RoutingConstants.ADDITIONAL_REPORT;
    this.activityFeedUrl = RoutingConstants.ACTIVITY_FEED_URL;
    this.dashboardUrl = RoutingConstants.DASHBOARD;
    this.contactsUrl = RoutingConstants.CONTACTS_URL;
    this.mediaLibraryUrl = RoutingConstants.MEDIA_LIBRARY_URL;
    this.linkBuilderUrl = RoutingConstants.LINK_BUILDER_URL;
    this.landingPagesUrl = RoutingConstants.SOCIAL_PAGES_URL;
    this.callToActionUrl = RoutingConstants.CALL_TO_ACTION_URL;
    this.socialQueueUrl = RoutingConstants.SOCIAL_QUEUE_URL;
    this.contentChannelUrl = RoutingConstants.CONTENT_CHANNEL_URL;
    this.contentStreamUrl = RoutingConstants.CONTENT_STREAM_URL;
    this.trackingSettingsUrl = RoutingConstants.ONLINE_TRACKING_URL;
    this.accountWebsiteUrl = RoutingConstants.SOCIALSALES_ACCOUNT_WEBSITE_URL;
    this.menuActive = this.menuActive ? this.menuActive : RoutingConstants.DASHBOARD_URL;
    this.subscriptionAndBilling = RoutingConstants.SUBSCRIPTION_AND_BILLING;
    this.locationsAndLanguages = RoutingConstants.LOCATIONS_AND_LANGUAGES;
    this.pdfGuides = RoutingConstants.PDF_GUIDES;
    this.videoTutorials = RoutingConstants.VIDEO_TUTORIALS;
    this.resources = RoutingConstants.RESOURCES
    this.contactUs = RoutingConstants.CONTACT_US;
    this.faq = RoutingConstants.FAQ;
    this.personalInformation = RoutingConstants.PERSONAL_INFORMATION;
    this.appsPage = RoutingConstants.APPS_PAGE_URL;
    this.accessibilityStatement = RoutingConstants.ACCESSIBILITY_URL;
    this.businesstrainingUrl=RoutingConstants.BUSINESS_TRAINING_URL;
    this.producttrainingUrl=RoutingConstants.PRODUCT_TRAINING_URL;
  }


  iframeLoader(event) {
    this.menuActive = event.target.id;
    const currentWindowUrl = window.location.pathname;
    if (currentWindowUrl.indexOf(this.menuActive) < 0) {
      if ((this.menuActive === RoutingConstants.SOCIALSALES_ACCOUNT_WEBSITE_URL) ||
        (this.menuActive === RoutingConstants.CONTENT_CHANNEL_URL)) {
        this.router.navigateByUrl(RoutingConstants.ACCOUNT_SOCIAL_SALES_URL + event.target.id);
      } else if(this.menuActive === RoutingConstants.RESOURCES) {
        this.router.navigateByUrl(RoutingConstants.HELP + event.target.id);
      }
      else{
        this.router.navigateByUrl(RoutingConstants.SOCIAL_SALES_URL + event.target.id);
      }
    } else {
      if ((this.menuActive === RoutingConstants.SOCIALSALES_ACCOUNT_WEBSITE_URL) ||
        (this.menuActive === RoutingConstants.CONTENT_CHANNEL_URL)) {
        this.router.navigateByUrl(RoutingConstants.ACCOUNT_SOCIAL_SALES_URL + event.target.id).then(() => window.location.reload());
      }
      else if(this.menuActive === RoutingConstants.RESOURCES) {
        this.router.navigateByUrl(RoutingConstants.HELP + event.target.id).then(() => window.location.reload());;
      } else {
        this.router.navigateByUrl(RoutingConstants.SOCIAL_SALES_URL + event.target.id).then(() => window.location.reload());
      }
    }
  }
  goToDashboard() {
    this.router.navigateByUrl(RoutingConstants.DASHBOARD_URL);
    this.menuActive = RoutingConstants.DASHBOARD_URL;
    this.openToolsItems = false;
    this.openDashboardItems = false;
    this.openMyAccountSettings = false;
    this.openHelpMenu = false;
    this.openTrainingToolsItems=false;
  }
  goToApps() {
    this.closeAllMenus();
    this.router.navigateByUrl(RoutingConstants.APPS_PAGE_URL);
    this.menuActive = RoutingConstants.APPS_PAGE_URL;
    this.openTrainingToolsItems=false;
  }
  loadReport(event) {
    this.menuActive = event.target.id;
    switch (event.target.id) {
      case RoutingConstants.VOLUME:
        this.router.navigateByUrl(RoutingConstants.VOLUME_URL);
        break;
      case RoutingConstants.ORDER:
        this.router.navigateByUrl(RoutingConstants.ORDERS_URL);
        break;
      case RoutingConstants.EARNINGS:
        if(this.homeCountryCode){
          this.router.navigateByUrl(RoutingConstants.EARNINGS_URL);
        }
        break;
      case RoutingConstants.INCENTIVES:
        this.router.navigateByUrl(RoutingConstants.INCENTIVES_URL);
        break;
      case RoutingConstants.FOCUS_GROUP:
        this.router.navigateByUrl(RoutingConstants.FOCUS_GROUP_URL);
        break;
      case RoutingConstants.DOWNLINE:
        this.router.navigateByUrl(RoutingConstants.DOWNLINE_URL);
        break;
      case RoutingConstants.DOWNLINE_MANAGERS:
        this.router.navigateByUrl(RoutingConstants.DOWNLINE_MANAGERS_URL);
        break;
      case RoutingConstants.RETAIL_CUSTOMERS:
        this.router.navigateByUrl(RoutingConstants.RETAIL_CUSTOMER_URL);
        break;
      case RoutingConstants.RECURITMENTS:
        this.router.navigateByUrl(RoutingConstants.RECRUITMENT_URL);
        break;
      case RoutingConstants.RETENTION:
        this.router.navigateByUrl(RoutingConstants.RETENTION_URL);
        break;
      case RoutingConstants.PRODUCTIVITY:
        this.router.navigateByUrl(RoutingConstants.PRODUCTIVITY_URL);
        break;
      case RoutingConstants.DEVELOPMENT:
        this.router.navigateByUrl(RoutingConstants.DEVELOPMENT_URL);
        break;
      case RoutingConstants.ADVANCED_REPORTS:
        this.router.navigateByUrl(RoutingConstants.ADVANCED_REPORTS_URL);
        break;
        case RoutingConstants.EARNINGS_CC_SUMMARY:
          this.router.navigateByUrl(RoutingConstants.EARNINGS_CC_SUMMARY_URL);
          break;
          case RoutingConstants.EARNINGS_25CC_MANAGERS:
          this.router.navigateByUrl(RoutingConstants.EARNINGS_25CC_MANAGERS_URL);
          break;
      case RoutingConstants.REPORTERIZER_WORKFLOW:
        this.router.navigateByUrl(RoutingConstants.REPORTERIZER_WORKFLOW_URL);
        break;
        case RoutingConstants.FOUR_CORE_SUMMARY:
          this.router.navigateByUrl(RoutingConstants.FOUR_CORE_SUMMARY_URL);
          break;
          case RoutingConstants.FOUR_CORE_DEVELOPMENT:
            this.router.navigateByUrl(RoutingConstants.FOUR_CORE_LEVEL_MOVE_UP_URL);
            break;
            case RoutingConstants.FOUR_CORE_PRODUCTIVITY:
              this.router.navigateByUrl(RoutingConstants.FOUR_CORE_PRODUCTIVITY_URL);
              break;
              case RoutingConstants.FOUR_CORE_RECRUITMENT:
                this.router.navigateByUrl(RoutingConstants.FOUR_CORE_RECRUITMENT_URL);
                break;
                case RoutingConstants.FOUR_CORE_RETENTION:
                  this.router.navigateByUrl(RoutingConstants.FOUR_CORE_RETENTION_URL);
                  break;
      case RoutingConstants.ADDITIONAL_REPORT:
        this.router.navigateByUrl(RoutingConstants.ADDITIONAL_REPORT_URL);
        break;
    }
  }
  loadSettings(event) {
    this.menuActive = event.target.id;
    switch (event.target.id) {
      case RoutingConstants.SUBSCRIPTION_AND_BILLING:
        this.router.navigateByUrl(RoutingConstants.SUBSCRIPTION_AND_BILLING_URL);
        break;
      case RoutingConstants.PERSONAL_INFORMATION:
        this.router.navigateByUrl(RoutingConstants.PERSONAL_INFORMATION_URL);
        break;
      case RoutingConstants.LOCATIONS_AND_LANGUAGES:
        this.router.navigateByUrl(RoutingConstants.LOCATIONS_AND_LANGUAGES_URL);
        break;
      case RoutingConstants.ONLINE_TRACKING_URL:
        this.router.navigateByUrl(RoutingConstants.TRACKING_SETTINGS_URL);
        break;
      case RoutingConstants.SECURITY_PREFERENCES:
        this.router.navigateByUrl(RoutingConstants.SECURITY_PREFERENCES_URL);
          break;

    }

  }
  loadHelp(event) {
    this.menuActive = event.target.id;
    switch (event.target.id) {
      case RoutingConstants.CONTACT_US:
        this.router.navigateByUrl(RoutingConstants.CONTACT_US_URL);
        break;
      case RoutingConstants.PDF_GUIDES:
        this.router.navigateByUrl(RoutingConstants.PDF_GUIDES_URL);
        break;
      case RoutingConstants.VIDEO_TUTORIALS:
        this.router.navigateByUrl(RoutingConstants.VIDEO_TUTORIALS_URL);
        break;
      case RoutingConstants.FAQ:
        this.router.navigateByUrl(RoutingConstants.FAQ_URL);
        break;
        case RoutingConstants.RESOURCES:
          this.router.navigateByUrl(RoutingConstants.RESOURCE_LIBRARY_URL + '/' + 'help');
          break;
    }
  }
  logout(): void {
    this.ssoService.logOutSettings();
    const redirectedUrl = this.ssoService.getSSOLogoutUrl(
      window.location.origin
    );
    this.ssoService.ssoRedirect(redirectedUrl);
  }
  openDashboard() {
    this.openDashboardItems = !this.openDashboardItems;
    this.openToolsItems = false;
    this.openMyAccountSettings = false;
    this.openHelpMenu = false;
    this.openContentItems = false;
    this.openFourCoreContentList = false;
    this.openTrainingToolsItems=false;
  }
  openTools() {
    this.openToolsItems = !this.openToolsItems;
    this.openMyAccountSettings = false;
    this.openHelpMenu = false;
    this.openDashboardItems = false;
    this.openContentItems = false;
    this.openFourCoreContentList = false;
    this.openTrainingToolsItems=false;
  }
  expandTrainingTools(){
    this.openMyAccountSettings = false;
    this.openHelpMenu = false;
    this.openDashboardItems = false;
    this.openContentItems = true;
    this.openFourCoreContentList = false;
    this.openTrainingToolsItems=true;
    this.openToolsItems=true;
  }
  closeTrainingTourPopup(){
      this.openMyAccountSettings = false;
      this.openHelpMenu = false;
      this.openDashboardItems = false;
      this.openContentItems = true;
      this.openFourCoreContentList = false;
      this.openTrainingToolsItems=false;
      this.openToolsItems=true;
  }
  openTrainingTools(){
    this.openTrainingToolsItems=!this.openTrainingToolsItems;
    this.openMyAccountSettings = false;
    this.openHelpMenu = false;
    this.openDashboardItems = false;
    this.openContentItems = false;
    this.openFourCoreContentList = false;
    this.openToolsItems = false;

  }
  openMyAccount() {
    this.openMyAccountSettings = !this.openMyAccountSettings;
    this.openHelpMenu = false;
    this.openDashboardItems = false;
    this.openToolsItems = false;
    this.openContentItems = false;
    this.openFourCoreContentList = false;
    this.openTrainingToolsItems=false;
  }
  openHelp() {
    this.openHelpMenu = !this.openHelpMenu;
    this.openDashboardItems = false;
    this.openToolsItems = false;
    this.openMyAccountSettings = false;
    this.openContentItems = false;
    this.openFourCoreContentList = false;
    this.openTrainingToolsItems=false;
  }
  closeAllMenus() {
    this.openDashboardItems = false;
    this.openHelpMenu = false;
    this.openToolsItems = false;
    this.openMyAccountSettings = false;
    this.openContentItems = false;
    this.openFourCoreContentList = false;
    this.openTrainingToolsItems=false;
  }
  changeSettings() {
    this.router.navigate(['dashboard/settings']);
  }
  toggleFunction() {
    if (this.menuActive !== GuidedTourConstants.REPORTS_TOUR) {
      this.toggleStatus = !this.toggleStatus;
      this.messageService.sendToggleStatus(this.toggleStatus);
    }
  }

  openContents() {
    this.openContentItems = !this.openContentItems;
  }

  openFourCoreContents() {
    this.openFourCoreContentList = !this.openFourCoreContentList;
  }
  toggleSideNav() {
    const width = window.innerWidth > 0 ? window.innerWidth : document.documentElement.clientWidth;
    if (width <= 1024) {
      this.toggleStatus = false;
      this.messageService.sendToggleStatus(this.toggleStatus);
    }
  }
  hideNavigation() {
    this.showNavigation = false;
  }
  toggleSubMenuList() {
    this.isSubMenuOpened = !this.isSubMenuOpened;
  }

  /**
   * while clicking on Take the Tour link, Reports popup will be opened
   * openReportsTourPopup()
   * @param menu
   */
  openReportsTourPopup(menu: string): void {
    this.openDashboardItems = true;
    this.menuActive = menu;
    this.selectedTour = menu;
    this.showTourPopup = true;
    this.openFourCoreContentList = !this.openFourCoreContentList;
    this.openMyAccountSettings = false;
  }

  /**
   * @description Opening tools tour popup
   * @param {string} menu
   * @memberof NavbarComponent
   * openToolsTourPopup()
   */
  openToolsTourPopup(menu: string): void {
    this.openTrainingToolsItems=!this.openTrainingToolsItems;
    this.openToolsItems = true;
    this.openContentItems = true;
    this.menuActive = menu;
    this.selectedTour = menu;
    this.showTourPopup = true;
  }

  ngOnDestroy() {
    this.toggleStatusSubscription?.unsubscribe();
    this.eventSubscription?.unsubscribe();
    this.subscription?.unsubscribe();
    this.nonTitanUserTitleSubscription?.unsubscribe();
    this.toggleStatusSubscription?.unsubscribe();
    this.homeCountryCodeSubscription?.unsubscribe();
    this.navbarSubmenuStatusSubscription?.unsubscribe();
    this.sideNavOpenSubscription?.unsubscribe();
    this.routedPathSubscription?.unsubscribe();
    this.closeReportsMenuStatusSubscription?.unsubscribe();
    this.closeToolsMenuStatusSubscription?.unsubscribe();
    this.socialSalesRoutingPageSubscription?.unsubscribe();
    this.showReportsSubMenuSubscription?.unsubscribe();
    this.showAccountsSubMenuSubscription?.unsubscribe();
    this.iscloseAllmenuSubscription?.unsubscribe();
    this.isSuspendedUserSubscription?.unsubscribe();
  }
}
